import SimpleBar from 'simplebar';
import xhrwrap from "./components/xhrWrap";
import contact from "./components/contact";

const XHRWrap = xhrwrap();

document.addEventListener('DOMContentLoaded',function(){

    contact(XHRWrap);

    // Image popup
    class AVTPopup {
        constructor(settings) {
            this.imageClass = settings.imageClass === null ? "" : settings.imageClass;
            this.descriptionClass = settings.descriptionClass === null ? "" : settings.descriptionClass;
            this.elements = {
                popupWrap: null,
                popupImg: null,
                popupImgWrap: null,
                popupDescr: null,
                popupClose: null,
                popupNext: null,
                popupPrev: null,
                galleryElements: [],
                images: [],
                counter: null,
                counterTotal: null,
                counterCurrent: null,
                galleryWrap: null
            }
            this.states = {
                activeIndex: 0,
                startPoint: null
            }
            this.excludedItems = {
                class: settings.excludedItems.class === null ? [] : settings.excludedItems.class,
                data: settings.excludedItems.data === null ? [] : settings.excludedItems.data,
                id: settings.excludedItems.id === null ? [] : settings.excludedItems.id
            }
            this.description = settings.counter === null ? false : settings.description;
            this.counter = settings.counter === null ? false : settings.counter;
            this.gallery = settings.gallery === null ? false : settings.gallery;
            this.nextPrev = settings.nextPrev === null ? false : settings.nextPrev;
            this.init();
        }

        init() {
            if (this.imageClass == "") {
                console.error("AVT Popup: No images class");
                return;
            }
            this.createPopup();
            this.setEvents();
        }
        setEvents() {
            this.showPopup();
            this.closePopup();
            this.zoomPicture();
            this.createGallery();
            this.createDescription();
            this.setGallery();
            this.keyboardEvents();
            this.setNextPrev();
            this.createCounter();
            this.touchEvents();
        }

        createPopup() {
            const body = document.querySelector("body");
            const popupWrap = document.createElement("div");
            const popupImg = document.createElement("img");
            const popupImgWrap = document.createElement("div");
            const popupClose = document.createElement("div");
            popupImgWrap.classList.add("avt-popup__image-wrap");
            popupClose.classList.add("avt-popup__close");
            popupImg.classList.add("avt-popup__image");
            popupWrap.classList.add("avt-popup");
            popupImgWrap.appendChild(popupImg);
            popupWrap.appendChild(popupImgWrap);
            popupWrap.appendChild(popupClose);
            body.appendChild(popupWrap);
            this.elements.popupClose = popupClose;
            this.elements.popupWrap = popupWrap;
            this.elements.popupImg = popupImg;
            this.elements.popupImgWrap = popupImgWrap;
        }
        createDescription() {
            const popupDescr = document.createElement("div");
            popupDescr.classList.add("avt-popup__description");
            this.elements.popupImgWrap.appendChild(popupDescr);
            this.elements.popupDescr = popupDescr;
        }
        checkExcluded(element) {
            for (var i = 0; i < this.excludedItems.class.length; i++) {
                if (element.className.indexOf(this.excludedItems.class[i]) != -1) {
                    return true;
                }
            }
            for (var i = 0; i < this.excludedItems.data.length; i++) {
                if (element.hasAttribute(this.excludedItems.data[i])) {
                    return true;
                }
            }
            for (var i = 0; i < this.excludedItems.id.length; i++) {
                if (element.id == this.excludedItems.id[i]) {
                    return true;
                }
            }
            return false;
        }
        showPopup() {
            const self = this;
            const images = document.querySelectorAll(this.imageClass);
            for (let i = 0; i < images.length; i++) {
                images[i].dataset.index = i;
                self.elements.images.push(images[i]);
                if (!(this.checkExcluded(images[i]))) {
                    images[i].addEventListener("click", function(event){
                        event.preventDefault();
                        self.states.activeIndex = this.dataset.index;
                        let imgSrc = this.href;
                        let imgAlt = this.querySelector("img").alt;
                        if(imgSrc.indexOf("http://") == -1 || imgSrc.indexOf("https://")) {
                        }
                        self.elements.popupImg.src = imgSrc;
                        self.elements.popupImgWrap.style.backgroundImage = "url('" + imgSrc + "')";
                        if (self.description) {
                            let descrTxt = "";
                            if (self.descriptionClass != "") {
                                let descr = this.querySelector(self.descriptionClass);
                                if (descr === null) {
                                    descr = this.parentElement.querySelector(self.descriptionClass);
                                    if (!(descr)) {
                                        descr = this.parentElement.parentElement.querySelector(self.descriptionClass);
                                    }
                                }
                                if (descr != null) {
                                    descrTxt = descr.innerHTML;
                                }
                            }
                            if (descrTxt == "") {
                                self.elements.popupDescr.innerHTML = imgAlt;
                            }
                            else {
                                self.elements.popupDescr.innerHTML = descrTxt;
                            }
                        }
                        else {
                            self.elements.popupDescr.innerHTML = imgAlt;
                        }


                        self.elements.popupWrap.classList.add("open");
                        self.setActiveGalleryEl();
                        self.setCounter();
                    });
                }

            }
        }
        closePopup() {
            const self = this;
            this.elements.popupClose.addEventListener("click", function(){
                self.elements.popupWrap.classList.remove("open");
                self.elements.popupWrap.classList.remove("zoom");
                self.elements.popupImgWrap.classList.remove("zoom");
            });
            this.elements.popupWrap.addEventListener("click", function(){
                this.classList.remove("open");
            });
        }

        zoomPicture() {
            const self = this;
            this.elements.popupImg.addEventListener("click", function(event){
                event.preventDefault();
                event.stopPropagation();
                self.elements.popupWrap.classList.toggle("zoom");
                this.parentElement.classList.toggle("zoom");
            });
        }

        nextImage() {
            const activeIndex = this.states.activeIndex;
            let newIndex = parseInt(this.states.activeIndex) + 1;
            if (newIndex == this.elements.images.length) {
                newIndex = 0;
            }
            const nextEl = this.elements.galleryElements[newIndex];
            this.states.activeIndex = nextEl.dataset.index;
            this.elements.popupImg.src = nextEl.dataset.src;
            this.elements.popupImgWrap.style.backgroundImage = "url('" + nextEl.dataset.src + "')";
            this.elements.popupDescr.innerHTML = nextEl.dataset.description;
            this.setActiveGalleryEl();
            this.setCounter();
        }
        prevImage() {
            const activeIndex = this.states.activeIndex;
            let newIndex = parseInt(this.states.activeIndex) - 1;
            if (newIndex < 0) {
                newIndex = this.elements.images.length - 1;
            }
            const nextEl = this.elements.galleryElements[newIndex];
            this.states.activeIndex = nextEl.dataset.index;
            this.elements.popupImg.src = nextEl.dataset.src;
            this.elements.popupImgWrap.style.backgroundImage = "url('" + nextEl.dataset.src + "')";
            this.elements.popupDescr.innerHTML = nextEl.dataset.description;
            this.setActiveGalleryEl();
            this.setCounter();
        }
        setNextPrev() {
            const self = this;
            if (this.nextPrev) {
                const popupNext = document.createElement("div");
                const popupPrev = document.createElement("div");
                popupNext.classList.add("avt-popup__next");
                popupPrev.classList.add("avt-popup__prev");
                this.elements.popupImgWrap.appendChild(popupNext);
                this.elements.popupImgWrap.appendChild(popupPrev);
                this.elements.popupNext = popupNext;
                this.elements.popupPrev = popupPrev;

                popupNext.addEventListener("click", function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    self.nextImage();
                });
                popupPrev.addEventListener("click", function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    self.prevImage();
                });
            }
        }

        keyboardEvents() {
            const self = this;
            window.addEventListener("keydown", function(e){
                let imageClass = self.imageClass;
                if (imageClass.indexOf(".") != -1) {
                    imageClass = imageClass.replace(".", "");
                }

                // Escape
                if((e.key == 'Escape' || e.key == 'Esc' || e.keyCode==27) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    e.preventDefault();
                    self.elements.popupWrap.classList.remove("open");
                    self.elements.popupWrap.classList.remove("zoom");
                    self.elements.popupImgWrap.classList.remove("zoom");
                }
                // Left arrow
                if((e.key == 'ArrowLeft' || e.keyCode == 37) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    self.prevImage();
                }
                // Right arrow
                if((e.key == 'ArrowRight' || e.keyCode == 39) && (self.elements.popupWrap.className.indexOf("open") != -1)){
                    self.nextImage();
                }
            });
        }

        createGallery() {
            const self = this;
            if (this.gallery) {
                const images = document.querySelectorAll(this.imageClass);
                const galleryWrap = document.createElement("div");
                galleryWrap.classList.add("avt-popup__gallery");
                this.elements.popupWrap.appendChild(galleryWrap);
                this.elements.gallery = galleryWrap;
                for (var i = 0; i < images.length; i++) {
                    const bgSrc = images[i].href;
                    const img = images[i].querySelector("img");
                    let imgAlt;
                    if (img != null) {
                        imgAlt = images[i].querySelector("img").alt;
                    }
                    else {
                        imgAlt = "";
                    }
                    const galleryEl = document.createElement("div");
                    galleryEl.classList.add("avt-popup__gallery-item");

                    if (this.description && this.descriptionClass) {

                        let descrTxt = "";
                        let descr = images[i].querySelector(self.descriptionClass);
                        if (descr === null) {
                            descr = images[i].parentElement.querySelector(self.descriptionClass);
                            if (descr === null) {
                                const parentEl = images[i].parentElement;
                                descr = parentEl.parentElement.querySelector(self.descriptionClass);
                            }
                        }
                        if (self.descriptionClass != null && descr != null) {
                            descrTxt = descr.innerHTML;
                        }
                        galleryEl.dataset.description = descrTxt;
                    }
                    else {
                        galleryEl.dataset.description = imgAlt;
                    }

                    galleryEl.dataset.index = i;
                    galleryEl.dataset.src = bgSrc;
                    galleryEl.style.backgroundImage = "url('" + bgSrc + "')";
                    this.elements.galleryElements.push(galleryEl);
                    this.elements.gallery.appendChild(galleryEl);
                }
            }
        }
        setGallery() {
            const self = this;
            if (this.gallery) {
                for (var i = 0; i < this.elements.galleryElements.length; i++) {
                    const galleryEl = this.elements.galleryElements[i]
                    galleryEl.addEventListener("click", function(event){
                        event.preventDefault();
                        event.stopPropagation();
                        self.states.activeIndex = this.dataset.index;
                        self.elements.popupImg.src = this.dataset.src;
                        self.elements.popupImgWrap.style.backgroundImage = "url('" + this.dataset.src + "')";
                        self.elements.popupDescr.innerHTML = this.dataset.description;
                        self.elements.popupWrap.classList.add("open");
                        self.setActiveGalleryEl();
                        self.setCounter();
                    });
                }
            }
            else {
                self.elements.popupWrap.classList.add("--img");
            }
        }
        setActiveGalleryEl() {
            const self = this;
            if (this.gallery) {
                var galleryElements = this.elements.galleryElements;
                for (var i = 0; i < galleryElements.length; i++) {
                    if (i == this.states.activeIndex) {
                        galleryElements[i].classList.add("active");
                    }
                    else {
                        galleryElements[i].classList.remove("active");
                    }
                }
            }
        }

        createCounter() {
            if (this.counter) {
                const counter = document.createElement("div");
                const counterCurrent = document.createElement("div");
                const counterTotal = document.createElement("div");
                counter.classList.add("avt-popup__counter");
                counterCurrent.classList.add("avt-popup__counter-current");
                counterTotal.classList.add("avt-popup__counter-total");
                counter.appendChild(counterCurrent);
                counter.appendChild(counterTotal);
                this.elements.popupImgWrap.appendChild(counter);
                this.elements.counter = counter;
                this.elements.counterTotal = counterTotal;
                this.elements.counterCurrent = counterCurrent;
            }
        }
        setCounter() {
            if (this.counter) {
                const total = this.elements.images.length;
                const current = parseInt(this.states.activeIndex) + 1;
                this.elements.counterTotal.innerText = total;
                this.elements.counterCurrent.innerText = current;
            }
        }
        touchEvents() {
            const self = this;
            this.elements.popupImgWrap.addEventListener("touchstart", function(event){
                self.states.startPoint = event.touches[0].clientX;
            });
            this.elements.popupImgWrap.addEventListener("touchmove", function(event){
                const newPoint = event.touches[0].clientX;
                if (newPoint < self.states.startPoint) {
                    if (self.states.startPoint - newPoint > window.innerWidth / 10) {
                        self.nextImage();
                        self.states.startPoint = newPoint;
                    }
                }
                else if (newPoint > self.states.startPoint) {
                    if (newPoint - self.states.startPoint > window.innerWidth / 10) {
                        self.prevImage();
                        self.states.startPoint = newPoint;
                    }
                }
            });
        }
    }

    const popupSettings = {
        imageClass: ".imagesInContent",
        gallery: true,
        nextPrev: true,
        counter: true,
        excludedItems: {
            class: [],
            data: ["data-oryginal"],
            id: []
        }
    }

    if (document.querySelectorAll(".imagesInContent").length > 0) {
        window.addEventListener("load", function(){
            let timer = setTimeout(function(){
                const articlePopup = new AVTPopup(popupSettings);
            }, 10)
        });
    }


    let simpleBarWrapper = document.querySelector('.course-list.scroll');

    if(simpleBarWrapper) {
        const simpleBarHome = new SimpleBar(simpleBarWrapper, {
            autoHide: false,
        });

        let scrollTop       = document.getElementsByClassName('scroll-top')[0];
        let scrollBottom    = document.getElementsByClassName('scroll-bottom')[0];

        if(scrollTop) {
            scrollTop.addEventListener('click',function(){
                simpleBarHome.getScrollElement().scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            })
        }

        if(scrollBottom) {
            scrollBottom.addEventListener('click',function(){
                var topScroll = simpleBarHome.getScrollElement().scrollTopMax;
                simpleBarHome.getScrollElement().scroll({
                    top: topScroll,
                    left: 0,
                    behavior: 'smooth',
                });
            })
        }
    }


    var videoFragments  = document.querySelectorAll('.selected-fragments__item, .course-single__preview');
    var videoPopup      = document.getElementsByClassName('video-popup')[0];
    var videoPopupClose = document.getElementsByClassName('video-popup__close')[0];

    if(videoPopupClose) {
        videoPopupClose.addEventListener('click',function(){
            var videoIframe = videoPopup.getElementsByTagName('iframe')[0];

            setTimeout(() => {
                videoIframe.src = '';
                videoIframe.width = '';
                videoIframe.height = '';
            }, 300);

            videoPopup.classList.remove('show');
        })
    }
    if(videoFragments) {
        for(var i = 0; i < videoFragments.length; i++) {
            videoFragments[i].addEventListener('click',function(){
                if(videoPopup.classList.contains('show')) {
                    videoPopup.classList.remove('show');
                } else {
                    var vimeoLink   = 'https://player.vimeo.com/video/' + this.dataset.id;
                    var vimeoWidth  = this.dataset.width;
                    var vimeoHeight = this.dataset.height;
                    var videoIframe = videoPopup.getElementsByTagName('iframe')[0];

                    videoIframe.src = vimeoLink;
                    videoIframe.width = vimeoWidth;
                    videoIframe.height = vimeoHeight;

                    videoPopup.classList.add('show');
                }
            })
        }
    }


    const singleMessage = document.getElementsByClassName("single-course__info-message")[0];
    const courseMessage = document.getElementsByClassName("course-unlock-message")[0];
    // Form
    const checkCourseCode = function (form) {
        return new Promise(function(resolve,reject) {
            const xhr = new XMLHttpRequest();
            xhr.addEventListener("load", function() {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
                if (xhr.status === 404 || xhr.status === 500) {
                    if (singleMessage) {
                        singleMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                        singleMessage.classList.add("error");
                        singleMessage.classList.add("open");
                    }
                    if (courseMessage) {
                        courseMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                        courseMessage.classList.add("error");
                    }
                }
            });
            xhr.addEventListener("error", function() {
                if (singleMessage) {
                    singleMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                    singleMessage.classList.add("error");
                    singleMessage.classList.add("open");
                }
                if (courseMessage) {
                    courseMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                    courseMessage.classList.add("error");
                }
            });
            xhr.open("POST", "https://kursy.avt.pl/kod", true);
            xhr.setRequestHeader('X-Requested-With','XMLHttpRequest');
            const formData = new FormData(form);
            xhr.type = "json";
            xhr.send(formData);
        });
    }


    const mainForm = document.querySelector(".home-unlock");

    if(mainForm) {
        mainForm.addEventListener("submit", function(e){
            e.preventDefault();
            const courseList = document.querySelector(".course-list .simplebar-content");
            const unlockedText = document.getElementsByClassName("unlocked-text")[0];
            const lockedText = document.getElementsByClassName("locked-text")[0];
            const courseUnlocked = document.getElementsByClassName("course-unlocked")[0];
            const infoBox = document.querySelector('.single-course__box.home-box');
            const menuMyCourses = document.getElementsByClassName('header__menu-list-item-my')[0];
            const insertInMenu = document.getElementsByClassName('header__menu-list')[0];

            checkCourseCode(mainForm).then(resolve => {
                courseMessage.innerText = "";
                courseMessage.classList.remove("success");
                courseMessage.classList.remove("error");
                let response;
                try {
                    response = JSON.parse(resolve);
                }
                catch(e) {
                    courseMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                    courseMessage.classList.add("error");
                    return;
                }
                if (response.result) {
                    const course = document.createElement("li");
                    const courseImg = document.createElement("img");
                    const courseLink = document.createElement("a");
                    const courseName = document.createElement("span");

                    course.classList.add("course-list__item");
                    courseLink.classList.add("course-list__item-text");
                    courseLink.href = response.course.url;
                    courseName.innerText = response.course.title;
                    courseImg.src = "/assets-kursy/images/unlock-green.svg";
                    courseLink.appendChild(courseImg);
                    courseLink.appendChild(courseName);
                    course.appendChild(courseLink);
                    courseList.appendChild(course);
                    courseMessage.innerText = response.message;
                    courseMessage.classList.add("success");
                    if (unlockedText) {
                        unlockedText.classList.remove("d-none");
                    }
                    if (lockedText) {
                        lockedText.classList.add("d-none");
                    }
                    if (courseUnlocked) {
                        courseUnlocked.classList.remove("d-none");
                    }
                    if(infoBox) {
                        infoBox.classList.add("d-none");
                    }
                    if(!menuMyCourses) {
                        const menuItem = document.createElement('li');
                        const menuLink = document.createElement('a');

                        menuItem.classList.add('header__menu-list-item-my');
                        menuLink.href = 'https://kursy.avt.pl/moje-kursy';
                        menuLink.textContent = 'Moje kursy';
                        menuItem.appendChild(menuLink);

                        insertInMenu.prepend(menuItem);
                    }
                }
                else {
                    courseMessage.innerText = response.message;
                    courseMessage.classList.add("error");
                }
            });
        });
    }


    const singleForm = document.querySelector(".single-course__info-form");

    if (singleForm) {
        singleForm.addEventListener("submit", function(e){
            e.preventDefault();
            checkCourseCode(singleForm).then(resolve => {
                singleMessage.innerHTML = "";
                singleMessage.classList.remove("open");
                singleMessage.classList.remove("success");
                singleMessage.classList.remove("error");
                let response;
                try {
                    response = JSON.parse(resolve);
                }
                catch(e) {
                    singleMessage.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                    singleMessage.classList.add("error");
                    singleMessage.classList.add("open");
                    return;
                }
                if (response.result) {
                    singleMessage.classList.add("success");
                    singleMessage.classList.add("open");
                    const link = document.createElement("a");
                    link.href = response.course.url;
                    link.innerText = "Kliknij tutaj, by rozpocząć kurs: " + response.course.title;
                    singleMessage.appendChild(link);
                }
                else {
                    singleMessage.innerText = response.message;
                    singleMessage.classList.add("error");
                    singleMessage.classList.add("open");
                }
            });
        });
    }

    String.prototype.toHHMMSS = function () {
        let sec_num = parseInt(this, 10); // don't forget the second param
        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        if (hours < 1) {
            return minutes+":"+seconds;
        }
        else {
            return hours+':'+minutes+':'+seconds;
        }
    }
    String.prototype.HHMMSStoSeconds = function() {
        let arr = this.split(":");
        let time = 0;
        if (arr.length == 3) {
            let hours = parseInt(arr[0]) * 3600;
            let minutes = parseInt(arr[1]) * 60;
            let seconds = parseInt(arr[2]);
            time = hours + minutes + seconds;
        }
        else if (arr.length == 2) {
            let minutes = parseInt(arr[0]) * 60;
            let seconds = parseInt(arr[1]);
            time = minutes + seconds;
        }
        else if (arr.length == 1) {
            time = parseInt(arr[0]);
        }
        else {
            console.log("Invalid format");
            return;
        }
        return time;
    }
    class CustomVideoPlayer {
        constructor (settings) {
            this.element = settings.element === undefined || settings.element === null ? null : settings.element;
            this.movies = settings.movies === undefined || settings.movies === null ? null : settings.movies;
            this.moviesList = settings.moviesList === undefined || settings.movies === null ? false : settings.moviesList;
            this.player = null;
            this.video = null;
            this.settings = {
                initialMovie: settings.initialMovie === undefined || settings.movies === null ? null : settings.initialMovie,
                movieDescription: settings.movieDescription === undefined || settings.movies === null ? false : settings.movieDescription,
                hideTime: settings.hideTime === undefined || settings.hideTime === null ? 3000 : settings.hideTime,
                disableRightClick: settings.disableRightClick === undefined || settings.disableRightClick === null ? true : settings.disableRightClick,
                movieToc: settings.movieToc === undefined || settings.movieToc === null ? false : settings.movieToc,
                playerTitle: settings.playerTitle === undefined || settings.playerTitle === null ? false : settings.playerTitle,
                customScroll: settings.customScroll === undefined || settings.customScroll === null ? false : settings.customScroll,
            };
            this.controls = {
                play: null,
                pause: null,
                volume: null,
                totalTime: null,
                next: null,
                prev: null,
                fullscreen: null,
                playList: null,
                videoWrapper: null,
                videoTitle: null,
                videoDescription: null,
                videoCounter: null,
            };
            this.list = {
                el: null,
                wrap: null,
                btn: null,
            }
            this.progress = {
                wrap: null,
                bar: null,
                thumb: null
            };
            this.volume = {
                el: null,
                wrap: null,
                bar: null,
                icon: null,
            }
            this.state = {
                id: null,
                player: {
                    isPlaying: false,
                    currentTime: 0,
                    totalTime: 0,
                    volume: 0,
                    activeIndex: 0,
                    fullscreen: false,
                    playList: false,
                    lastVolumeLv: null,
                    audio: false,
                },
                click: {
                    volumeClick: false,
                    progressClick: false,
                    element: null,
                },
                move: {
                    progressClickX: 0,
                    volClickY: 0,
                    volClickY: 0,
                    minX: null,
                    maxX: null,
                    minY: null,
                    maxY: null,
                    fullscreen: false,
                },
                moveTimer: null
            };
            this.toc = {
                element: null,
                moviesTocs: [],
                activeToc: [],
                activeIndex: 0,
            }
            this.listMovies = [];
            this.init();
        }
        checkVideoLength(index, arr) {
            const self = this;
            return new Promise(function(resolve, reject){
                let video = document.createElement("video");
                video.src = self.movies[index].src;
                video.addEventListener("loadedmetadata", function(){
                    self.movies[index].duration = video.duration;
                    let durationText = new String(video.duration).toHHMMSS();
                    self.listMovies[index].querySelector("[data-player='list-movie-duration']").innerText = durationText;
                    resolve();
                });
                arr.push(video);
            });
        }
        hideControls() {
            const self = this;
            if (self.state.player.fullscreen) {
                self.state.moveTimer = setTimeout(function(){
                    self.element.dataset.screenMove = "false";
                    self.state.move.fullscreen = true;
                }, self.settings.hideTime);
            }
        }
        setBaseElements() {
            if (!(this.element === null)) {
                this.element.dataset.play = "false";
                this.element.dataset.move = "false";
                this.element.dataset.fullscreen = "false";
                this.element.dataset.screenMove = "false";
                this.video = this.element.querySelector("[data-player='video']");
                this.controls.pause = this.element.querySelector("[data-player='pause']") === null || this.element.querySelector("[data-player='pause']") === undefined ? null : this.element.querySelector("[data-player='pause']");
                this.controls.play = this.element.querySelector("[data-player='play']") === null || this.element.querySelector("[data-player='play']") === undefined ? null : this.element.querySelector("[data-player='play']");
                this.volume.el = this.element.querySelector("[data-player='volume']") === null || this.element.querySelector("[data-player='volume']") === undefined ? null : this.element.querySelector("[data-player='volume']");
                this.volume.wrap = this.element.querySelector("[data-player='volume-wrap']") === null || this.element.querySelector("[data-player='volume-wrap']") === undefined ? null : this.element.querySelector("[data-player='volume-wrap']");
                this.volume.bar = this.element.querySelector("[data-player='volume-bar']") === null || this.element.querySelector("[data-player='volume-bar']") === undefined ? null : this.element.querySelector("[data-player='volume-bar']");
                this.volume.icon = this.element.querySelector("[data-player='volume-icon']") === null || this.element.querySelector("[data-player='volume-icon']") === undefined ? null : this.element.querySelector("[data-player='volume-icon']");
                this.controls.currentTime = this.element.querySelector("[data-player='time-current']") === null || this.element.querySelector("[data-player='time-current']") === undefined ? null : this.element.querySelector("[data-player='time-current']");
                this.controls.totalTime = this.element.querySelector("[data-player='time-total']") === null || this.element.querySelector("[data-player='time-total']") === undefined ? null : this.element.querySelector("[data-player='time-total']");
                this.controls.fullscreen = this.element.querySelector("[data-player='fullscreen-btn']") === null || this.element.querySelector("[data-player='fullscreen-btn']") === undefined ? null : this.element.querySelector("[data-player='fullscreen-btn']");
                this.controls.next = this.element.querySelector("[data-player='next']") === null || this.element.querySelector("[data-player='next']") === undefined ? null : this.element.querySelector("[data-player='next']");
                this.controls.prev = this.element.querySelector("[data-player='prev']") === null || this.element.querySelector("[data-player='prev']") === undefined ? null : this.element.querySelector("[data-player='prev']");
                this.controls.playList = this.element.querySelector("[data-player='play-list']") === null || this.element.querySelector("[data-player='play-list']") === undefined ? null : this.element.querySelector("[data-player='play-list']");
                this.controls.videoWrapper = this.element.querySelector("[data-player='video-wrapper']") === null || this.element.querySelector("[data-player='video-wrapper']") === undefined ? null : this.element.querySelector("[data-player='video-wrapper']");
                this.controls.videoTitle = this.element.querySelector("[data-player='video-title']") === null || this.element.querySelector("[data-player='video-title']") === undefined ? null : this.element.querySelector("[data-player='video-title']");
                this.controls.videoDescription = this.element.querySelector("[data-player='video-description']") === null || this.element.querySelector("[data-player='video-description']") === undefined ? null : this.element.querySelector("[data-player='video-description']");
                this.controls.videoCounter = this.element.querySelector("[data-player='video-counter']") === null || this.element.querySelector("[data-player='video-counter']") === undefined ? null : this.element.querySelector("[data-player='video-counter']");
                this.controls.tocCounter = this.element.querySelector("[data-player='toc-counter']") === null || this.element.querySelector("[data-player='toc-counter']") === undefined ? null : this.element.querySelector("[data-player='toc-counter']");
                this.controls.tocTitle = this.element.querySelector("[data-player='toc-active-title']") === null || this.element.querySelector("[data-player='toc-active-title']") === undefined ? null : this.element.querySelector("[data-player='toc-active-title']");
                this.progress.wrap = this.element.querySelector("[data-player='progress-wrap']") === null || this.element.querySelector("[data-player='progress-wrap']") === undefined ? null : this.element.querySelector("[data-player='progress-wrap']");
                this.progress.bar = this.element.querySelector("[data-player='progress-bar']") === null || this.element.querySelector("[data-player='progress-bar']") === undefined ? null : this.element.querySelector("[data-player='progress-bar']");
                this.progress.thumb = this.element.querySelector("[data-player='progress-thumb']") === null || this.element.querySelector("[data-player='progress-thumb']") === undefined ? null : this.element.querySelector("[data-player='progress-thumb']");
                this.player = this.video;
            }
        }
        setElements() {
            const self = this;
            if (!(this.element === null)) {
                if (this.settings.playerTitle) {
                    this.element.querySelector("[data-player='player-title']").innerText = this.settings.playerTitle;
                }
                if (this.settings.movieDescription) {
                    this.element.dataset.movieDescription = "true";
                    this.controls.videoDescription = this.element.querySelector("[data-player='video-description']") === null || this.element.querySelector("[data-player='video-description']") === undefined ? null : this.element.querySelector("[data-player='video-description']");
                }
                if (this.moviesList) {
                    if(this.element.querySelector("[data-player='list']") === null || this.element.querySelector("[data-player='list']") === undefined) {
                        this.list.el = null;
                        this.moviesList = false;
                    }
                    else {
                        this.list.el = this.element.querySelector("[data-player='toc']");
                    }
                }
                if (this.volume.bar != null) {
                    this.volume.bar.style.width = "100%";
                    this.player.volume = 1;
                    this.state.player.volume = 1;
                }
                if (this.progress.bar != null) {
                    this.progress.bar.style.width = 0;
                }
                if (this.movies != null) {
                    let index = 0;
                    if (this.settings.initialMovie != null) {
                        if (this.settings.initialMovie > -1 && this.settings.initialMovie < this.movies.length) {
                            index = this.settings.initialMovie;
                        }
                        else if (this.settings.initialMovie < 0) {
                            index = 0;
                        }
                        else if (this.settings.initialMovie >= this.movies.length) {
                            index = this.movies.length - 1
                        }
                    }
                    this.player.addEventListener("loadeddata", function(){
                        setTimeout(function() {
                            self.element.dataset.audio = self.checkAudio() ? "true" : "false";
                        }, 100)
                    })
                    this.player.src = this.movies[index].src;
                    this.state.player.activeIndex = index;
                    if (this.movies[index].hasOwnProperty("format")) {
                        this.player.type = "video/" + this.movies[index].format;
                    }
                    else {
                        this.player.type = "video/mp4";
                    }
                    if (this.movies[index].hasOwnProperty("title") && this.settings.movieDescription && this.controls.videoTitle != null) {
                        let indexText = index + 1;
                        this.controls.videoCounter.innerText = "Film " + indexText + "/" + self.movies.length;
                        this.controls.videoTitle.innerText = this.movies[index].title;
                    }
                    if (this.movies[index].hasOwnProperty("description") && this.settings.movieDescription && this.controls.videoDescription != null) {
                        this.controls.videoDescription.innerText = this.movies[index].description;
                    }
                    if (this.movies.length > 1) {
                        this.controls.next.style.display = "block";
                        this.controls.prev.style.display = "block";
                        if (this.moviesList) {
                            if (!(this.element.querySelector("[data-player='list-btn']") === undefined || this.element.querySelector("[data-player='list-btn']") === null )) {
                                this.list.btn = this.element.querySelector("[data-player='list-btn']");
                                this.list.btn.dataset.open = "false";
                                this.list.el.style.display = "block";
                            }
                            else {
                                this.moviesList = false;
                            }
                            if (!(this.element.querySelector("[data-player='list-wrapper']") === undefined || this.element.querySelector("[data-player='list-wrapper']") === null)) {
                                this.list.wrapper = this.element.querySelector("[data-player='list-wrapper']");
                                this.list.el.style.display = "block";
                            }
                            else {
                                this.moviesList = false;
                            }
                        }
                    }
                    else {
                        this.moviesList = false;
                    }
                }
                if (this.settings.movieToc && this.movies != null && this.movies.length > 1) {
                    self.list.btn.addEventListener("click", function(e){
                        if (self.list.el.dataset.open == "true") {
                            self.list.el.dataset.open = "false";
                            this.dataset.open = "false";
                        }
                        else {
                            self.list.el.dataset.open = "true";
                            this.dataset.open = "true";
                        }
                    });
                    for (let i = 0; i < self.movies.length; i++) {
                        let movie = document.createElement("div");
                        let movieImage = document.createElement("img");
                        let movieImageWrap = document.createElement("div");
                        let movieDuration = document.createElement("div");
                        let movieText = document.createElement("div");
                        let movieTitle = document.createElement("div");
                        let movieDescription = document.createElement("div");
                        movie.dataset.player = "list-movie";
                        if (self.state.player.activeIndex == i) {
                            movie.dataset.active = "true";
                        }
                        else {
                            movie.dataset.active = "false";
                        }
                        movie.dataset.index = i;
                        movie.dataset.toc = "false";
                        movieDuration.dataset.player = "list-movie-duration";
                        movieDuration.innerText = "";
                        movieText.dataset.player = "list-movie-text";
                        movieTitle.dataset.player = "list-movie-title";
                        movieDescription.dataset.player = "list-movie-description";
                        movieImage.dataset.player = "list-movie-image";
                        movieImage.src = self.movies[i].image;
                        movieImageWrap.dataset.player = "list-image-wrap";
                        movieTitle.innerText = self.movies[i].title;
                        movieDescription.innerText = self.movies[i].description;
                        movieText.appendChild(movieTitle);
                        if (self.movies[i].hasOwnProperty("toc") && self.movies[i].toc.length > 0) {
                            movie.dataset.toc = "true";
                            let movieCount = document.createElement("div");
                            movieCount.dataset.player = "movie-toc-count";
                            let activeCount = document.createElement("div");
                            activeCount.dataset.player = "movie-active-toc";
                            let countText = "";
                            let count = self.movies[i].toc.length;
                            let lastNumber = new String(self.movies[i].toc.length);
                            lastNumber = lastNumber[lastNumber.length - 1];
                            if (count == 1) {
                                countText += "rozdział";
                            }
                            else if ((count == 2 || count == 3 || count == 4) || (count > 20 && (lastNumber == 2 || lastNumber == 3 || lastNumber == 4))) {
                                countText += "rozdziały";
                            }
                            else {
                                countText += "rozdziałów";
                            }
                            movieCount.innerText = self.movies[i].toc.length + " " + countText;
                            movieText.appendChild(movieCount);
                            movieText.appendChild(activeCount);
                        }
                        movieText.appendChild(movieDescription);
                        movieImageWrap.appendChild(movieImage);
                        movieImageWrap.appendChild(movieDuration);
                        movie.appendChild(movieImageWrap);
                        movie.appendChild(movieText);
                        if (self.movies[i].hasOwnProperty("toc") && self.movies[i].toc.length > 0) {
                            let btn = document.createElement("div");
                            btn.dataset.player="movie-toc-btn";
                            movie.appendChild(btn);
                            btn.addEventListener("click", function(e) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!(this.nextSibling.classList.contains("open"))) {
                                    for (var i = 0; i < self.toc.moviesTocs.length; i++) {
                                        self.toc.moviesTocs[i].parentElement.classList.remove("open");
                                        self.toc.moviesTocs[i].parentElement.parentElement.classList.remove("open");
                                    }
                                    this.nextSibling.classList.add("open");
                                    this.parentElement.classList.add("open");
                                }
                                else {
                                    this.parentElement.classList.remove("open");
                                    this.nextSibling.classList.remove("open");
                                }
                            });
                        }
                        self.listMovies.push(movie);
                        movie.addEventListener("click", function(e){
                            self.setMovie(this.dataset.index);
                        });
                        self.list.wrapper.appendChild(movie);
                    }
                    for (var i = 0; i < this.movies.length; i++) {
                        this.buildToc(i);
                    }
                    self.setActiveToc();
                    self.setActiveTocTitle();
                }
                if (this.element.querySelector("[data-player='player-counter']") && this.movies.length > 0) {
                    let text = this.movies.length + " ";
                    let count = this.movies.length;
                    let lastNumber = this.movies.length.toString();
                    lastNumber = lastNumber[lastNumber.length - 1];
                    if (count == 1) {
                        text += "film";
                    }
                    else if ((count == 2 || count == 3 || count == 4) || (count > 20 && (lastNumber == 2 || lastNumber == 3 || lastNumber == 4))) {
                        text += "filmy";
                    }
                    else {
                        text += "filmów";
                    }
                    this.element.querySelector("[data-player='player-counter']").innerText = text;
                }
                if (this.list.wrapper && this.settings.customScroll && SimpleBar) {
                    new SimpleBar(this.list.wrapper);
                }
            }
            else {
                return;
            }
        }
        buildToc(index) {
            const self = this;
            if (this.movies[index].hasOwnProperty("toc") && this.settings.movieToc && self.movies[index].toc.length > 0) {
                let tocList = document.createElement("div");
                tocList.dataset.player = "toc-list";
                tocList.dataset.index = index;
                let toc = this.movies[index].toc;
                let movieToc = document.createElement("div");
                movieToc.dataset.player = "movie-toc";
                movieToc.dataset.index = index;
                tocList.dataset.activeIndex = 0;
                tocList.addEventListener("click", function(e){
                    e.stopPropagation();
                    if (this.dataset.index == self.state.player.activeIndex) {
                        if (e.target.dataset.player == "toc-item") {
                            let time = e.target.dataset.second;
                            self.player.currentTime = time;
                        }
                    }
                    else {
                        if (e.target.dataset.player == "toc-item") {
                            self.setMovie(e.target.parentElement.dataset.index);
                            let time = e.target.dataset.second;
                            self.player.currentTime = time;
                        }
                    }
                });
                movieToc.appendChild(tocList);
                for (var i = 0; i < toc.length; i++) {
                    let tocItem = document.createElement("div");
                    tocItem.dataset.player = "toc-item";
                    let title = "<span data-player='toc-item-title'>" + toc[i].title + "</span>";
                    let time = "<span data-player='toc-item-time'>" + toc[i].time + "</span>";
                    tocItem.dataset.time = toc[i].time;
                    tocItem.dataset.second = toc[i].time.HHMMSStoSeconds();
                    tocItem.dataset.index = i;
                    tocItem.innerHTML = title + "<span data-player='toc-item-separator'>-</span>" + time;
                    tocList.appendChild(tocItem);
                }
                this.toc.moviesTocs[index] = tocList;
                this.listMovies[index].appendChild(movieToc);
            }
        }
        setDataId() {
            let id = (Math.random() * 10000).toFixed(0);
            this.state.id = "video-" + id;
            this.progress.thumb.dataset.id = "video-" + id;
            this.volume.bar.dataset.id = "video-" + id;
            this.volume.wrap.dataset.id = "video-" + id;
            this.volume.el.dataset.id = "video-" + id;
        }
        setActiveToc() {
            const self = this;
            self.toc.activeToc = [];
            if (self.movies[self.state.player.activeIndex].hasOwnProperty("toc") && self.movies[self.state.player.activeIndex].toc.length > 0) {
                let activeToc = self.movies[self.state.player.activeIndex].toc;
                for (var i = 0; i < activeToc.length; i++) {
                    self.toc.activeToc.push(activeToc[i].time.HHMMSStoSeconds());
                }
            }
        }
        checkAudio() {
            return this.player.mozHasAudio ||
            Boolean(this.player.webkitAudioDecodedByteCount) ||
            Boolean(this.player.audioTracks && this.player.audioTracks.length);
        }
        setMovie(newIndex) {
            newIndex = parseInt(newIndex);
            const self = this;
            self.player.pause();
            self.state.player.isPlaying = false;
            self.element.dataset.play = "false";
            self.listMovies[self.state.player.activeIndex].dataset.active = "false";
            self.state.player.activeIndex = newIndex;
            self.player.src = self.movies[newIndex].src;
            if (self.movies[newIndex].hasOwnProperty("format")) {
                self.player.type = "video/" + self.movies[newIndex].format;
            }
            else {
                self.player.type = "video/mp4";
            }
            if (self.settings.movieDescription && self.controls.videoTitle != null && self.controls.videoDescription != null) {
                let indexText = parseInt(newIndex) + 1;
                self.controls.videoCounter.innerText = "Film " + indexText + "/" + self.movies.length;
                if (self.movies[newIndex].hasOwnProperty("title")) {
                    self.controls.videoTitle.innerText = self.movies[newIndex].title;
                }
                else {
                    self.controls.videoTitle.innerText = "";
                }
                if (self.movies[newIndex].hasOwnProperty("description")) {
                    self.controls.videoDescription.innerText = self.movies[newIndex].description;
                }
                else {
                    self.controls.videoDescription.innerText = "";
                }
                self.controls.tocCounter.innerText = "";
                self.controls.tocTitle.innerText = "";
            }
            if (self.settings.movieToc) {
                self.toc.activeIndex = 0;
            }
            self.progress.bar.style.width = 0;
            self.player.load();
            self.setActiveToc();
            self.setActiveTocTitle();
            self.state.player.isPlaying = true;
            self.element.dataset.play = true;
            self.listMovies[newIndex].dataset.active = "true";
            self.player.play();
        }
        setActiveTocItem(time) {
            const self = this;
            let newIndex = 0;
            if (self.toc.activeToc.indexOf(time) != -1) {
                newIndex = self.toc.activeToc.indexOf(time);
                self.toc.moviesTocs[self.state.player.activeIndex].dataset.activeIndex = self.toc.activeToc.indexOf(time);
                self.toc.activeIndex = self.toc.activeToc.indexOf(time);
            }
            else {
                for (var i = 0; i < self.toc.activeToc.length; i++) {
                    if (self.toc.activeToc[i] < time) {
                        newIndex = i;
                    }
                }
                self.toc.activeIndex = newIndex;
                self.toc.moviesTocs[self.state.player.activeIndex].dataset.activeIndex = newIndex;
            }
            self.setActiveTocTitle();
        }
        setActiveTocTitle(){
            const self = this;
            let activeToc = self.listMovies[self.state.player.activeIndex];
            if (self.movies[self.state.player.activeIndex].hasOwnProperty("toc") && self.movies[self.state.player.activeIndex].toc.length > 0 && self.movies[self.state.player.activeIndex]) {
                let tocCounter = activeToc.querySelector("[data-player='movie-active-toc']");
                tocCounter.innerText = "Rozdział " + (self.toc.activeIndex + 1) + " / " + self.toc.activeToc.length;
                self.controls.tocCounter.innerText = "Rozdział " + (self.toc.activeIndex + 1) + "/" + self.toc.activeToc.length;
                self.controls.tocTitle.innerText = self.movies[self.state.player.activeIndex].toc[self.toc.activeIndex].title;
            }
        }
        pause() {
            this.player.pause();
            this.state.player.isPlaying = false;
            this.element.dataset.play = "false";
        }
        setControls() {
            const self = this;
            if (!(self.element === null)) {
                if (self.controls.play != null) {
                    self.controls.play.addEventListener("click", function(){
                        if (self.state.player.isPlaying) {
                            self.player.pause();
                            self.state.player.isPlaying = false;
                            self.element.dataset.play = "false";
                            clearTimeout(self.state.moveTimer);
                        }
                        else {
                            self.player.play();
                            self.state.player.isPlaying = true;
                            self.element.dataset.play = "true";
                            self.hideControls();
                        }
                    });
                    window.addEventListener("keydown", function(e){
                        let newTime;
                        if (e.keyCode == 32) {
                            if (self.state.player.isPlaying) {
                                self.player.pause();
                                self.state.player.isPlaying = false;
                                self.element.dataset.play = "false";
                                clearTimeout(self.state.moveTimer);
                            }
                            else {
                                self.player.play();
                                self.state.player.isPlaying = true;
                                self.element.dataset.play = "true";
                                self.hideControls();
                            }
                        }
                        if (e.key == 'ArrowLeft' || e.keyCode == 37) {
                            newTime = self.player.currentTime - 5;
                            if (newTime < 0) {
                                newTime = 0;
                            }
                            self.player.currentTime = newTime;
                        }
                        if (e.key == 'ArrowRight' || e.keyCode == 39) {
                            newTime = self.player.currentTime + 5;
                            if (newTime > self.player.duration) {
                                newTime = self.player.duration - 0.00001;
                            }
                            self.player.currentTime = newTime;
                            self.player.pause();
                        }
                    })
                }
                if (self.controls.videoWrapper != null) {
                    self.controls.videoWrapper.addEventListener("click", function(){
                        if (self.state.player.isPlaying) {
                            self.player.pause();
                            self.state.player.isPlaying = false;
                            self.element.dataset.play = "false";
                            clearTimeout(self.state.moveTimer);
                        }
                        else {
                            self.player.play();
                            self.state.player.isPlaying = true;
                            self.element.dataset.play = "true";
                            if (self.state.player.fullscreen) {
                                self.hideControls();
                            }
                        }
                    });
                }
                if (self.controls.totalTime != null && self.controls.currentTime != null) {
                    self.player.addEventListener("loadedmetadata", function(){
                        let totalTime = self.player.duration.toString();
                        let currentTime = self.player.currentTime.toString();
                        totalTime = totalTime.toHHMMSS();
                        currentTime = currentTime.toHHMMSS();
                        self.state.player.totalTime = totalTime;
                        self.controls.totalTime.innerText = totalTime;
                        self.state.player.currentTime = currentTime;
                        self.controls.currentTime.innerText = currentTime;
                    });
                }
                if (self.progress.wrap != null && self.progress.bar != null && self.volume.el != null && self.volume.wrap != null) {
                    self.player.addEventListener("timeupdate", function(e){
                        let currentTime = self.player.currentTime.toString();
                        currentTime = currentTime.toHHMMSS();
                        self.controls.currentTime.innerText = currentTime;
                        if (!self.state.click.progressClick) {
                            self.progress.bar.style.width = (self.player.currentTime / self.player.duration) * 100 + "%";
                        }
                        if (self.toc.moviesTocs[self.state.player.activeIndex]) {
                            self.setActiveTocItem(currentTime.HHMMSStoSeconds());
                        }
                    });
                    self.player.addEventListener("ended", function(e){
                        if (!self.state.click.progressClick) {
                            if (self.state.player.playList) {
                                let newIndex = self.state.player.activeIndex + 1;
                                if (newIndex < self.movies.length) {
                                    self.setMovie(newIndex);
                                }
                                else {
                                    return;
                                }
                            }
                        }
                    });
                    self.progress.wrap.addEventListener("click", function(e){
                        e.stopPropagation();
                        let style = window.getComputedStyle(e.target);
                        let width = parseInt(style.getPropertyValue("width"));
                        let position = (e.layerX / width) * 100;
                        let totalTime = self.player.duration;
                        let currentTime = totalTime * (position / 100);
                        self.player.currentTime = currentTime;
                        self.progress.bar.width = position + "%";
                    });
                    self.progress.thumb.addEventListener("click", function(e){
                        e.stopPropagation();
                    });
                    self.volume.wrap.addEventListener("click", function(e){
                        if (e.target.dataset.id == self.state.id) {
                            e.stopPropagation();
                            let top = e.layerX;
                            let wrapWidth = this.offsetWidth;
                            let width = (top / wrapWidth * 100) + "%";
                            if (parseInt(width) > 100) {
                                width = "100%";
                            }
                            else if (parseInt(width) < 0) {
                                width = 0;
                            }
                            self.volume.bar.style.width = width;
                            self.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.lastVolumeLv = self.state.player.volume;
                            if (self.state.player.volume == 0) {
                                self.volume.icon.dataset.muted = "true";
                            }
                            else {
                                self.volume.icon.dataset.muted = "false";
                            }
                            self.element.dataset.move = "true";
                            self.state.click.element = e.target;
                        }
                    });
                    window.addEventListener("mousedown", function(e){
                        if (e.target.dataset.player == "volume-wrap" && e.target.dataset.id == self.state.id) {
                            e.stopPropagation();
                            self.state.move.volClickX = e.clientX;
                            self.state.click.volumeClick = true;
                            let top = e.layerX;
                            let wrapWidth = self.volume.wrap.offsetWidth;
                            let width = (top / wrapWidth * 100) + "%";
                            if (parseInt(width) > 100) {
                                width = "100%";
                            }
                            self.volume.bar.style.width = width;
                            self.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.lastVolumeLv = self.state.player.volume;
                            self.element.dataset.move = "true";
                            self.state.click.element = e.target;
                        }
                        if (e.target.dataset.player == "progress-thumb" && e.target.dataset.id == self.state.id) {
                            e.stopPropagation();
                            self.player.pause();
                            self.state.move.progressClickX = e.clientX;
                            self.state.click.progressClick = true;
                            self.element.dataset.move = "true";
                            self.state.click.element = e.target;
                        }
                    });
                    window.addEventListener("mouseup", function(e){
                        if (self.state.click.volumeClick) {
                            self.state.click.volumeClick = false;
                        }
                        if (self.state.click.progressClick) {
                            self.state.click.progressClick = false;
                            if (self.state.player.isPlaying) {
                                self.player.play();
                            }
                        }
                        self.element.dataset.move = "false";
                        self.state.click.element = null;
                    });
                    window.addEventListener("mousemove", function(e){
                        if (self.state.click.volumeClick && self.state.click.element.dataset.id == self.state.id) {
                            e.stopPropagation();
                            let oldPoint = self.state.move.volClickX;
                            let mouseX = e.clientX;
                            let wrapWidth = self.volume.wrap.offsetWidth;
                            let oldWidth = parseFloat(self.volume.bar.style.width);
                            let dif;
                            let difPerc;
                            if (oldPoint >= mouseX) {
                                dif = (oldPoint - mouseX);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.volume.bar.style.width = (oldWidth - difPerc) < 0 ? 0 : (oldWidth - difPerc) + "%";
                                self.player.volume = ((oldWidth - difPerc) / 100).toFixed(2) < 0 ? 0 : ((oldWidth - difPerc) / 100).toFixed(2);
                                self.state.player.volume = ((oldWidth - difPerc) / 100).toFixed(2) < 0 ? 0 : ((oldWidth - difPerc) / 100).toFixed(2);
                                if (oldWidth - difPerc >= 0) {
                                    self.state.move.volClickX = e.clientX;
                                }
                            }
                            else {
                                dif = (mouseX - oldPoint);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.volume.bar.style.width = (oldWidth + difPerc) > 100 ? "100%" : (oldWidth + difPerc) + "%";
                                self.player.volume = ((oldWidth + difPerc) / 100).toFixed(2) > 1 ? 1 : ((oldWidth + difPerc) / 100).toFixed(2);
                                self.state.player.volume = ((oldWidth + difPerc) / 100).toFixed(2) > 1 ? 1 : ((oldWidth + difPerc) / 100).toFixed(2);
                                if (oldWidth + difPerc <= 100) {
                                    self.state.move.volClickX = e.clientX;
                                }
                            }
                            if (self.state.player.volume == 0) {
                                self.volume.icon.dataset.muted = "true";
                            }
                            else {
                                self.volume.icon.dataset.muted = "false";
                            }
                            self.state.player.lastVolumeLv = self.state.player.volume;
                        }
                        if (self.state.click.progressClick && self.state.click.element.dataset.id == self.state.id) {
                            e.stopPropagation();
                            let oldPoint = self.state.move.progressClickX;
                            let mouseX = e.clientX;
                            let wrapWidth = self.progress.wrap.offsetWidth;
                            let oldWidth = parseFloat(self.progress.bar.style.width);
                            let dif;
                            let difPerc;
                            if (oldPoint <= mouseX) {
                                dif = (mouseX - oldPoint);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.progress.bar.style.width = (oldWidth + difPerc) > 100 ? "100%" : (oldWidth + difPerc) + "%";
                                if (oldWidth + difPerc <= 100) {
                                    self.state.move.progressClickX = e.clientX;
                                }
                            }
                            else {
                                dif = (oldPoint - mouseX);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.progress.bar.style.width = (oldWidth - difPerc) < 0 ? 0 : (oldWidth - difPerc) + "%";
                                if (oldWidth - difPerc >= 0) {
                                    self.state.move.progressClickX = e.clientX;
                                }
                            }
                            let currentTime = self.player.duration * (parseFloat(self.progress.bar.style.width) / 100);
                            self.controls.currentTime.innerText = currentTime.toString().toHHMMSS();
                            self.state.player.currentTime = currentTime;
                            self.player.currentTime = currentTime;
                        }
                        if (self.state.player.fullscreen) {
                            clearTimeout(self.state.moveTimer);
                            self.element.dataset.screenMove = "true";
                            self.state.move.fullscreen = true;
                            if (e.target.dataset.wrapper == "video") {
                                self.hideControls();
                            }
                        }
                    });
                    document.querySelector("body").addEventListener("mouseleave", function(e){
                        if (self.state.player.fullscreen) {
                            self.element.dataset.screenMove = "false";
                            self.state.move.fullscreen = false;
                        }
                    });
                    window.addEventListener("touchend", function(e){
                        if (self.state.click.volumeClick) {
                            self.state.click.volumeClick = false;
                        }
                        if (self.state.click.progressClick) {
                            self.state.click.progressClick = false;
                            if (self.state.player.isPlaying) {
                                self.player.play();
                            }
                        }
                        self.element.dataset.move = "false";
                        self.state.click.element = null;
                    });
                    window.addEventListener("touchstart", function(e){
                        if (self.state.player.fullscreen) {
                            clearTimeout(self.state.moveTimer);
                        }
                        if (e.target.dataset.player == "volume-wrap" && e.target.dataset.id == self.state.id) {
                            e.stopPropagation();
                            self.state.move.volClickX = e.touches[0].clientX;
                            self.state.click.volumeClick = true;
                            let top = e.touches[0].layerX;
                            let wrapWidth = self.volume.wrap.offsetWidth;
                            let width = (top / wrapWidth * 100) + "%";
                            if (parseInt(width) > 100) {
                                width = "100%";
                            }
                            self.volume.bar.style.width = width;
                            self.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.volume = (parseFloat(width) / 100).toFixed(2);
                            self.state.player.lastVolumeLv = self.state.player.volume;
                            self.element.dataset.move = "true";
                            self.state.click.element = e.target;
                        }
                        if (e.target.dataset.player == "progress-thumb" && e.target.dataset.id == self.state.id) {
                            e.stopPropagation();
                            self.player.pause();
                            self.state.move.progressClickX = e.touches[0].clientX;
                            self.state.click.progressClick = true;
                            self.element.dataset.move = "true";
                            self.state.click.element = e.target;
                        }
                    });
                    window.addEventListener("touchmove", function(e){
                        if (self.state.player.fullscreen) {
                            clearTimeout(self.state.moveTimer);
                        }
                        if (self.state.click.volumeClick && self.state.click.element.dataset.id == self.state.id) {
                            e.stopPropagation();
                            let oldPoint = self.state.move.volClickX;
                            let mouseX = e.touches[0].clientX;
                            let wrapWidth = self.volume.wrap.offsetWidth;
                            let oldWidth = parseFloat(self.volume.bar.style.width);
                            let dif;
                            let difPerc;
                            if (oldPoint >= mouseX) {
                                dif = (oldPoint - mouseX);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.volume.bar.style.width = (oldWidth - difPerc) < 0 ? 0 : (oldWidth - difPerc) + "%";
                                self.player.volume = ((oldWidth - difPerc) / 100).toFixed(2) < 0 ? 0 : ((oldWidth - difPerc) / 100).toFixed(2);
                                self.state.player.volume = ((oldWidth - difPerc) / 100).toFixed(2) < 0 ? 0 : ((oldWidth - difPerc) / 100).toFixed(2);
                                if (oldWidth - difPerc >= 0) {
                                    self.state.move.volClickX = e.touches[0].clientX;
                                }
                            }
                            else {
                                dif = (mouseX - oldPoint);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.volume.bar.style.width = (oldWidth + difPerc) > 100 ? "100%" : (oldWidth + difPerc) + "%";
                                self.player.volume = ((oldWidth + difPerc) / 100).toFixed(2) > 1 ? 1 : ((oldWidth + difPerc) / 100).toFixed(2);
                                self.state.player.volume = ((oldWidth + difPerc) / 100).toFixed(2) > 1 ? 1 : ((oldWidth + difPerc) / 100).toFixed(2);
                                if (oldWidth + difPerc <= 100) {
                                    self.state.move.volClickX = e.touches[0].clientX;
                                }
                            }
                            if (self.state.player.volume == 0) {
                                self.volume.icon.dataset.muted = "true";
                            }
                            else {
                                self.volume.icon.dataset.muted = "false";
                            }
                            self.state.player.lastVolumeLv = self.state.player.volume;
                        }
                        if (self.state.click.progressClick && self.state.click.element.dataset.id == self.state.id) {
                            e.stopPropagation();
                            let oldPoint = self.state.move.progressClickX;
                            let mouseX = e.touches[0].clientX;
                            let wrapWidth = self.progress.wrap.offsetWidth;
                            let oldWidth = parseFloat(self.progress.bar.style.width);
                            let dif;
                            let difPerc;
                            if (oldPoint <= mouseX) {
                                dif = (mouseX - oldPoint);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.progress.bar.style.width = (oldWidth + difPerc) > 100 ? "100%" : (oldWidth + difPerc) + "%";
                                if (oldWidth + difPerc <= 100) {
                                    self.state.move.progressClickX = e.touches[0].clientX;
                                }
                            }
                            else {
                                dif = (oldPoint - mouseX);
                                difPerc = ((dif / wrapWidth) * 100);
                                self.progress.bar.style.width = (oldWidth - difPerc) < 0 ? 0 : (oldWidth - difPerc) + "%";
                                if (oldWidth - difPerc >= 0) {
                                    self.state.move.progressClickX = e.touches[0].clientX;
                                }
                            }
                            let currentTime = self.player.duration * (parseFloat(self.progress.bar.style.width) / 100);
                            self.controls.currentTime.innerText = currentTime.toString().toHHMMSS();
                            self.state.player.currentTime = currentTime;
                            self.player.currentTime = currentTime;
                        }
                        if (self.state.player.fullscreen) {
                            clearTimeout(self.state.moveTimer);
                            self.element.dataset.screenMove = "true";
                            self.state.move.fullscreen = true;
                            if (e.target.dataset.wrapper == "video") {
                                self.hideControls();
                            }
                        }
                    });
                }
                if (self.controls.next != null && self.controls.prev != null) {
                    self.controls.next.addEventListener("click", function(e){
                        let oldIndex = parseInt(self.state.player.activeIndex);
                        let newIndex = oldIndex + 1;
                        if (newIndex < self.movies.length) {
                            self.setMovie(newIndex);
                            self.hideControls();
                        }
                        else {
                            return;
                        }
                    });
                    self.controls.prev.addEventListener("click", function(e){
                        let newIndex = self.state.player.activeIndex - 1
                        if (newIndex > -1) {
                            self.setMovie(newIndex);
                            self.hideControls();
                        }
                        else {
                            return;
                        }
                    });
                }
                if (self.controls.fullscreen != null) {
                    self.controls.fullscreen.addEventListener("click", function(e){
                        if (document.fullscreen) {
                            self.closeFullscreen();
                            self.state.player.fullscreen = false;
                            self.element.dataset.fullscreen = "false";
                            if (self.state.player.fullscreen) {
                                clearTimeout(self.state.moveTimer);
                            }
                        }
                        else {
                            self.openFullscreen(self.element);
                            self.state.player.fullscreen = true;
                            self.element.dataset.fullscreen = "true";
                            self.hideControls();
                        }
                    });
                    self.controls.videoWrapper.addEventListener("dblclick", function(e){
                        if (document.fullscreen) {
                            self.closeFullscreen();
                            self.state.player.fullscreen = false;
                            self.element.dataset.fullscreen = "false";
                        }
                        else {
                            self.openFullscreen(self.element);
                            self.state.player.fullscreen = true;
                            self.element.dataset.fullscreen = "true";
                            self.hideControls();
                        }
                    });
                }
                if (self.controls.playList != null) {
                    self.controls.playList.dataset.active = "true";
                    self.state.player.playList = true;
                    self.controls.playList.addEventListener("click", function(e){
                        if (self.controls.playList.dataset.active == "false") {
                            self.controls.playList.dataset.active = "true";
                            self.state.player.playList = true;
                        }
                        else {
                            self.controls.playList.dataset.active = "false";
                            self.state.player.playList = false;
                        }
                    });
                }
                if (self.volume.icon != null) {
                    self.volume.icon.addEventListener("click", function(e){
                        e.stopPropagation();
                        if (self.state.player.volume == 0) {
                            if (self.state.player.lastVolumeLv == 0) {
                                self.state.player.volume = 1;
                                self.player.volume = 1;
                                self.volume.bar.style.width = "100%";
                            }
                            else {
                                self.state.player.volume = self.state.player.lastVolumeLv;
                                self.player.volume = self.state.player.lastVolumeLv;
                                self.volume.bar.style.width = (self.state.player.volume * 100) + "%";
                            }
                            self.volume.icon.dataset.muted = "false";
                        }
                        else {
                            self.volume.icon.dataset.volume = self.state.player.volume;
                            self.state.player.volume = 0;
                            self.player.volume = 0;
                            self.volume.bar.style.width = 0;
                            self.volume.icon.dataset.muted = "true";
                        }
                    });
                }
            }
            else {
                return;
            }
        }
        closeFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            }
            else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            }
            else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
            this.state.move.fullscreen = false;
            this.element.dataset.screenMove = "false";
        }
        openFullscreen(elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            }
            else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            }
            else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
            this.state.move.fullscreen = true;
            this.element.dataset.screenMove = "true";
        }
        init() {
            const self = this;
            self.setBaseElements();
            self.setElements();
            self.setDataId();
            self.setControls();
            let promises = [];
            let checkVideos = [];
            if (self.settings.disableRightClick) {
                document.addEventListener("contextmenu", event => event.preventDefault());
            }
            for (var i = 0; i < self.movies.length; i++) {
                promises.push(self.checkVideoLength(i, checkVideos));
            }
            Promise.all(promises).then(function(){
                checkVideos = [];
            });
        }
    }
    let movies = false;
    if (typeof(courseMovies) === 'undefined') {
        movies = false;
    }
    else {
        movies = true;
    }
    if (movies) {
        var player = new CustomVideoPlayer({
            element: document.querySelector(".custom-video"),
            movies: courseMovies.movies,
            playerTitle: courseMovies.title,
            initialMovie: 0,
            hideTime: 2000,
            moviesList: true,
            disableRightClick: false,
            movieDescription: true,
            movieToc: true,
            customScroll: true,
        });
    }
    const movieBtn = document.getElementsByClassName("single-course__movie-btn");
    if (movieBtn) {
        const moviePopup = document.getElementsByClassName("single-course__movie-popup")[0];
        const closePopup = document.getElementsByClassName("single-course__movie-close")[0];

        if(moviePopup) {
            for(var i = 0; i < movieBtn.length; i++) {
                movieBtn[i].addEventListener("click", function(e){
                    moviePopup.classList.add("open");
                });
            }
        }
        if(closePopup) {
            closePopup.addEventListener("click", function(e){
                moviePopup.classList.remove("open");
                if (player) {
                    player.pause();
                }
            });
        }
    }

    const movieBtnNew = document.querySelector(".course-single__image.unlocked-course");
    const movieSmallBtn = document.querySelector('.course-single__share-btn.unlocked-course');

    if (movieBtnNew) {
        const moviePopup = document.getElementsByClassName("single-course__movie-popup")[0];
        const closePopup = document.getElementsByClassName("single-course__movie-close")[0];

        if(moviePopup) {
                movieBtnNew.addEventListener("click", function(e){
                    moviePopup.classList.add("open");
                });
        }
        if(closePopup) {
            closePopup.addEventListener("click", function(e){
                moviePopup.classList.remove("open");
                if (player) {
                    player.pause();
                }
            });
        }
    }

    if(movieSmallBtn) {
        const moviePopup = document.getElementsByClassName("single-course__movie-popup")[0];
        if(moviePopup) {
            movieSmallBtn.addEventListener("click", function(e){
                moviePopup.classList.add("open");
            });
    }
    }


    // New courses JS - START

    // Menu buttons - START

    var myCourses = document.getElementsByClassName('js-my-courses')[0];
    var backgroundElement = document.getElementsByClassName('background-on-page')[0];
    var myCoursesClose = document.getElementsByClassName('new-header__btns-wrapper-close')[0];

    if(myCourses) {
        myCourses.addEventListener('click', function(target) {
            if(target.target.classList.contains('js-my-courses')
                || target.target.classList.contains('new-header__btns-my-courses-text')
                || target.target.classList.contains('new-header__btns-wrapper-close')
              ) {
                if(this.classList.contains('active')) {
                    this.classList.remove('active');
                } else {
                    this.classList.add('active');
                }
                if(backgroundElement.classList.contains('show')) {
                    backgroundElement.classList.remove('show');
                } else {
                    backgroundElement.classList.add('show');
                }
            }
        })
    }

    var unlockCourse = document.getElementsByClassName('js-unlock-course');
    var unlockCoursePopup = document.getElementsByClassName('unlock-course-popup')[0];

    if(unlockCourse) {
        for(var i = 0; i < unlockCourse.length; i++) {
            unlockCourse[i].addEventListener('click',function() {
                if(unlockCoursePopup.classList.contains('show')) {
                    unlockCoursePopup.classList.remove('show');
                } else {
                    unlockCoursePopup.classList.add('show');
                }
            })
        }
    }

    if(unlockCoursePopup) {
        unlockCoursePopup.addEventListener('click',function(event){
            if(event.target.classList.contains('unlock-course-popup') || event.target.classList.contains('unlock-course-popup__right-top-close')) {
                unlockCoursePopup.classList.remove('show');
            }
        })
    }

    // Menu buttons - END

    // Single toc show - START

    var allTocElements = document.getElementsByClassName('course-single__toc-items-item');

    if(allTocElements) {
        for(var i = 0; i < allTocElements.length; i++) {
            allTocElements[i].addEventListener('click',function(){
                if(this.classList.contains('active')) {
                    this.classList.remove('active');
                } else {
                    for(var i = 0; i < allTocElements.length; i++) {
                        if(allTocElements[i].classList.contains('active')) {
                            allTocElements[i].classList.remove('active');
                        }
                    }
                    this.classList.add('active');
                }
            })
        }
    }

    // Single toc show - END

    // Single help display - START

    var helpBtn = document.getElementsByClassName('course-single__locked-help')[0];
    var stepsWrapper = document.getElementsByClassName('course-single__locked-steps')[0];
    var nextSteps    = document.getElementsByClassName('course-single__locked-steps-step-btn');
    var allSteps     = document.getElementsByClassName('course-single__locked-steps-step');
    var closeSteps   = document.getElementsByClassName('course-single__locked-steps-step-top-close');

    if(helpBtn) {
        helpBtn.addEventListener('click',function(){
            if(stepsWrapper.classList.contains('show')) {
                stepsWrapper.classList.remove('show');
                backgroundElement.classList.remove('show');
            } else {
                stepsWrapper.classList.add('show');
                backgroundElement.classList.add('show');
            }
        })
    }

    if(nextSteps) {
        for(var i = 0; i < nextSteps.length; i++) {
            nextSteps[i].addEventListener('click',function(){
                if(this.dataset.step == 1) {
                    allSteps[0].classList.remove('active');
                    allSteps[0].classList.add('inactive');
                    allSteps[1].classList.remove('inactive');
                    allSteps[1].classList.add('active');
                }
                if(this.dataset.step == 2) {
                    allSteps[1].classList.remove('active');
                    allSteps[1].classList.add('inactive');
                    allSteps[2].classList.remove('inactive');
                    allSteps[2].classList.add('active');
                }
                if(this.dataset.step == 3) {
                    backgroundElement.classList.remove('show');
                    stepsWrapper.classList.remove('show');
                    setTimeout(() => {
                        allSteps[2].classList.remove('inactive');
                        allSteps[2].classList.remove('active');
                        allSteps[1].classList.remove('inactive');
                        allSteps[0].classList.remove('inactive');
                        allSteps[0].classList.add('active');
                    }, 300);
                }
            })
        }
    }

    if(closeSteps) {
        for(var i = 0; i < closeSteps.length; i++) {
            closeSteps[i].addEventListener('click',function(){
                backgroundElement.classList.remove('show');
                stepsWrapper.classList.remove('show');
            })
        }
    }

    if(backgroundElement) {
        backgroundElement.addEventListener('click',function() {
            if(myCourses) {
                if(myCourses.classList.contains('active')) {
                    myCourses.classList.remove('active');
                }
            }
            if(stepsWrapper) {
                if(stepsWrapper.classList.contains('show')) {
                    stepsWrapper.classList.remove('show');
                    backgroundElement.classList.remove('show');
                }
            }
            if(backgroundElement.classList.contains('show')) {
                backgroundElement.classList.remove('show');
            }
        })
    }

    // Single help display - END


    // Unlock course - START

    const unlockCourseWrapper = document.querySelector(".unlock-course-popup__right-form");
    const unlockCourseInfoWrapper = document.getElementsByClassName('unlock-course-popup__right-description-info')[0];

    if (unlockCourseWrapper) {
        unlockCourseWrapper.addEventListener("submit", function(e){
            e.preventDefault();
            checkCourseCode(unlockCourseWrapper).then(resolve => {
                unlockCourseInfoWrapper.innerHTML = "";
                unlockCourseInfoWrapper.classList.remove("open");
                unlockCourseInfoWrapper.classList.remove("success");
                unlockCourseInfoWrapper.classList.remove("error");
                let response;
                try {
                    response = JSON.parse(resolve);
                }
                catch(e) {
                    unlockCourseInfoWrapper.innerText = "Niestety nie udało się nawiązać połączenia. Proszę spróbować za chwilę.";
                    unlockCourseInfoWrapper.classList.add("error");
                    unlockCourseInfoWrapper.classList.add("open");
                    return;
                }
                if (response.result) {
                    unlockCourseInfoWrapper.classList.add("success");
                    unlockCourseInfoWrapper.classList.add("open");
                    const link = document.createElement("a");
                    link.href = response.course.url;
                    link.innerText = "Kliknij tutaj, by rozpocząć kurs: " + response.course.title;
                    unlockCourseInfoWrapper.appendChild(link);
                }
                else {
                    unlockCourseInfoWrapper.innerText = response.message;
                    unlockCourseInfoWrapper.classList.add("error");
                    unlockCourseInfoWrapper.classList.add("open");
                }
            });
        });
    }

    // Unlock course - END

    // Burger menu - START

    var burgerBtn = document.getElementsByClassName('new-header__burger-icon')[0];
    var hiddenMenuWrapper = document.getElementsByClassName('new-header__hidden-menu')[0];
    var mainHeader = document.getElementsByClassName('new-header')[0];

    if(burgerBtn) {
        burgerBtn.addEventListener('click',function() {
            if(this.classList.contains('active')) {
                this.classList.remove('active');
            } else {
                this.classList.add('active');
            }
            if(hiddenMenuWrapper) {
                if(hiddenMenuWrapper.classList.contains('show')) {
                    hiddenMenuWrapper.classList.remove('show');
                } else {
                    hiddenMenuWrapper.classList.add('show');
                }
            }
            if(backgroundElement) {
                if(backgroundElement.classList.contains('show')) {
                    backgroundElement.classList.remove('show');
                } else {
                    backgroundElement.classList.add('show');
                }
            }
            if(mainHeader) {
                if(mainHeader.classList.contains('menu-background')) {
                    mainHeader.classList.remove('menu-background');
                } else {
                    mainHeader.classList.add('menu-background');
                }
            }
        })
    }

    var showUnlockCourseonHiddenMenu  = document.getElementsByClassName('new-header__hidden-menu-unlock-course')[0];
    var hiddenMenuOnlockCourseWrapper = document.getElementsByClassName('new-header__hidden-menu-unlock-wrapper')[0];
    var myCoursesHiddenMenuBtn        = document.getElementsByClassName('new-header__hidden-menu-my-courses')[0];
    var myCoursesHiddenMenuWrapper    = document.getElementsByClassName('new-header__hidden-menu-my-courses-wrapper')[0];
    var goBackBtn                     = document.getElementsByClassName('new-header__hidden-menu-go-back');

    if(showUnlockCourseonHiddenMenu) {
        showUnlockCourseonHiddenMenu.addEventListener('click',function() {
            if(hiddenMenuOnlockCourseWrapper.classList.contains('show')) {
                hiddenMenuOnlockCourseWrapper.classList.remove('show');
            } else {
                hiddenMenuOnlockCourseWrapper.classList.add('show');
            }
        });
    }

    if(myCoursesHiddenMenuBtn) {
        myCoursesHiddenMenuBtn.addEventListener('click', function() {
            if(myCoursesHiddenMenuWrapper.classList.contains('show')) {
                myCoursesHiddenMenuWrapper.classList.remove('show');
            } else {
                myCoursesHiddenMenuWrapper.classList.add('show');
            }
        })
    }

    if(goBackBtn) {
        for(var i = 0; i < goBackBtn.length; i++) {
            goBackBtn[i].addEventListener('click',function() {
                if(myCoursesHiddenMenuWrapper.classList.contains('show')) {
                    myCoursesHiddenMenuWrapper.classList.remove('show');
                }
                if(hiddenMenuOnlockCourseWrapper.classList.contains('show')) {
                    hiddenMenuOnlockCourseWrapper.classList.remove('show');
                }
            })
        }
    }



    // Burger menu - END

    // New courses JS - END


});
